<template>
  <header>
    <div class="wrap-box">
      <nav class="nav">
        <div @click="$router.push('/')" class="nav-title-logo">
          <!-- <div class="title-logo"></div>
      <div class="title-text">
        <h1>ЦПМСД №2</h1>
        <span>деснянського района м. Києва</span>
          </div>-->
        </div>
        <ul class="nav-links">
          <li>
            <router-link to="/">Головна</router-link>
          </li>
          <li>
            <router-link to="/public-documents">Публічна інформація</router-link>
          </li>
          <li>
            <router-link to="/contacts">Контакти</router-link>
          </li>
          <li>
            <router-link to="/structure">Структура</router-link>
          </li>
          <li>
            <router-link to="/life">Безбар'єрність</router-link>
          </li>
          <li>
            <router-link to="/news">Новини</router-link>
          </li>
        </ul>
        <div
          @click="toggleMenuSlider"
          class="burger-menu"
          style="background-image: url('/assets/contacts/menu.png');"
        ></div>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: "Navigation",
  methods: {
    toggleMenuSlider() {
      const menu = document.querySelector("#menu-slider");
      menu.classList.toggle("menu-closed");
    }
  }
};
</script>
<style scoped>
* {
  color: white;
}

header {
  width: 100vw;
  height: auto;
  color: white;
  background: #d0d6db;
}

.wrap-box {
  max-width: 1250px;
  width: calc(100% - 30px);
  margin: 0 auto 30px auto;
  padding: 1px 0;
}

nav.nav {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-title-logo {
  height: 100px;
  width: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/logo/logo.png");
}

.nav-title {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.title-logo {
  display: inline-block;
  width: 110px;
  height: 110px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url("../assets/logo/logo-transparent-bg-dark.png"); */
  background-image: url("../assets/logo/logo-transparent-bg.png");
}

.title-text {
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.title-text h1 {
  font-size: 35px;
}

.title-text span {
  font-size: 17px;
  opacity: 0.65;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 850px;
  height: 100%;
  flex-wrap: wrap;
}

.nav-links a {
  display: block;
  height: 100%;
  color: rgba(31, 69, 255, 0.55);
  width: auto;
  white-space: nowrap;
  font-size: 21px;
}

.nav-links a.router-link-exact-active {
  color: #f30722;
}

.burger-menu {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-title {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
  }

  .title-logo {
    width: 100px;
    height: 100px;
  }

  .title-text {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .title-text h1 {
    font-size: 30px;
  }

  .title-text span {
    font-size: 16px;
    opacity: 0.65;
  }

  .nav-links {
    margin-right: 10px;
    width: auto;
  }

  .nav-links a {
    display: block;
    height: 100%;
    width: auto;
    white-space: nowrap;
    font-size: 18px;
    margin: 0 8px;
  }
}

@media screen and (max-width: 1070px) {
  .nav-title {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }

  .title-logo {
    width: 100px;
    height: 100px;
  }

  .title-text h1 {
    font-size: 26px;
  }

  .title-text span {
    font-size: 15px;
    text-align: left;
    opacity: 0.65;
  }

  .nav-links {
    display: none;
  }

  .burger-menu {
    display: block;
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: invert(100%);
  }
}
</style>